<template>
  <div>
    <section>
      <Breadcrumb
        title="İletişim"
        :array="[
          { islink: true, url: '/', text: 'Ana Sayfa' },
          { islink: false, url: '', text: 'İletişim' },
        ]"
      />
    </section>

    <!--Contact-->
    <div class="sptb">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-12 mx-auto d-block mb-3" >
            <div class="card mb-0">
              <div class="card-body">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    id="name"
                    v-model="name"
                    placeholder="Ad Soyad"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    v-model="mail"
                    placeholder="E-Posta"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="phone"
                    class="form-control"
                    id="phone"
                    v-mask="'0(###) ###-##-##'"
                    v-model="phone"
                    placeholder="Telefon"
                  />
                </div>
                <div class="form-group">
                  <textarea
                    style="max-height: 120px; min-height: 120px"
                    class="form-control"
                    name="example-textarea-input"
                    rows="6"
                    v-model="message"
                    placeholder="Mesaj"
                  ></textarea>
                </div>
                <a
                  href="#"
                  class="btn btn-primary w-100"
                  @click="send"
                  id="saveButton"
                  >Gönder</a
                >
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 mx-auto d-block">
            <div class="card mb-0">
              <div class="card-body">
                <div class="form-control text-center">
                  0 (850) 888 90 48
                </div>
                <div class="form-control text-center mt-4">
                  Her Gün (10:00 - 18:00)
                </div>
                <div class="form-control text-center mt-4">
                  iletisim@terapivitrini.com
                </div>
                <div class="form-control text-center mt-4" style="height: 120px;border:none">
                  
                </div>
                <div
                  style="

                    width: fit-content;
                    display: flex;
                    margin: 0px auto;
                    align-content: space-between;
                    justify-content: space-around;
                    align-items: center;
                  "
                >
                  <li
                    v-if="$store.state.setting.facebook != null"
                    class="social-item"
                  >
                    <a
                      target="_blank"
                      class="social-icon text-dark"
                      :href="$store.state.setting.facebook"
                      ><i class="fa fa-facebook"></i
                    ></a>
                  </li>
                  <li
                    v-if="$store.state.setting.instagram != null"
                    class="social-item"
                  >
                    <a
                      target="_blank"
                      class="social-icon text-dark"
                      :href="$store.state.setting.instagram"
                      ><i class="fa fa-instagram"></i
                    ></a>
                  </li>
                  <li
                    v-if="$store.state.setting.twitter != null"
                    class="social-item"
                  >
                    <a
                      target="_blank"
                      class="social-icon text-dark"
                      :href="$store.state.setting.twitter"
                      ><i class="fa fa-twitter"></i
                    ></a>
                  </li>
                  <li
                    v-if="$store.state.setting.linkedin != null"
                    class="social-item"
                  >
                    <a
                      target="_blank"
                      class="social-icon text-dark"
                      :href="$store.state.setting.linkedin"
                      ><i class="fa fa-linkedin"></i
                    ></a>
                  </li>
                  <li
                    v-if="$store.state.setting.youtube != null"
                    class="social-item"
                  >
                    <a
                      target="_blank"
                      class="social-icon text-dark"
                      :href="$store.state.setting.youtube"
                      ><i class="fa fa-youtube"></i
                    ></a>
                  </li>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!--Contact-->

    <!--Statistics-->

    <!--/Statistics-->
  </div>
</template>
<style scoped>
.contactItem {
  line-height: 45px;
}
.social-item {
  float: left;
  width: 40px;
  background: #f2f2f2;
  
  margin: 5px;
  padding: 5px;
  text-align: center;
  font-size: 20px;
  border-radius: 50px;
}
.social-item>a{
  color:#bb7494 !important;
}
@media (max-width: 990px) {
  .contactItem {
    line-height: 0px;
    padding-bottom: 15px;
  }
}
</style>
<script>
import Breadcrumb from "../components/Breadcrumb.vue";
export default {
  metaInfo() {
    return {
      title: "İletişim - Terapi Vitrini",
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content:
            "İletişim - Terapi Vitrini ile 7/24 lisanslı, profesyonel bir terapiste erişim sağlayabilirsiniz",
        },
      ],
    };
  },
  created() {
    this.$store.state.header = true;
    this.$store.state.footer = true;
    this.$store.dispatch("setMetaContent", [
      "İletişim - Terapi Vitrini",
      "İletişim - Terapi Vitrini ile 7/24 lisanslı, profesyonel bir terapiste erişim sağlayabilirsiniz",
      "",
      "",
      "",
    ]);
  },
  data() {
    return {
      name: "",
      phone: "",
      phoneRegex: new RegExp(
        "^[+]*[0]*[ ]{0}[(][5]{1}[0-9]{1,3}[ ]{0,1}[)]{0,1}[ ]{0,1}[0-9]{1,3}[ ]{0,1}[0-9]{2}[-]{0,1}[0-9]{2}[]{0,1}[-./]{0,1}[-]{0,1}[0-9]{1,5}$"
      ),
      mail: "",
      mailRegex: new RegExp("^[w]+@([w-]+.)+[w-]{2,4}$"),
      message: "",
    };
  },
  components: { Breadcrumb },
  methods: {
    send() {
      let name = this.name;
      let number = this.phone;
      let mail = this.mail;

      let message = this.message;
      if (name == null || name == "") {
        this.$vToastify.warning("İsim Girmediniz", "Uyarı!");
      } else if (number == null || number == "") {
        this.$vToastify.warning("Telefon numarası girilmedi", "Uyarı!");
      } else if (!number.match(this.phoneRegex)) {
        this.$vToastify.warning("Hatalı telefon numarası", "Uyarı!");
      } else if (mail == null || mail == "") {
        this.$vToastify.warning("Mail adresi girilmedi", "Uyarı!");
      } else if (message == null || message == "") {
        this.$vToastify.warning("Mesajınızı girmediniz", "Uyarı!");
      } else {
        document.getElementById("saveButton").disabled = true;
        document.getElementById("saveButton").innerHTML =
          "Bilgileriniz Gönderiliyor";
        let contactInfo = {
          name: name,
          number: number,
          mail: mail,
          message: message,
        };
        this.$store.dispatch("contactInfo", contactInfo).then((value) => {
          this.$vToastify.success("Mesajınız alındı", "Başarılı!");
          this.$router.push("/");
        });
      }
    },
    backToTop() {
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        0
      );
      return false;
    },
  },
  mounted() {
    this.backToTop();
  },
};
</script>